<script>
import { Bar, mixins } from "vue-chartjs";

export default {
  extends: Bar,
  // mixins: [mixins.reactiveProp],
  mixins: [mixins.reactiveProp],
  props: {
    chartData: {
      type: Object,
      default: null,
    },
    showTitle: {
      type: Boolean,
      default: false,
    },
    showLegend: {
      type: Boolean,
      default: false,
    },
    maxValue: {
        type: Number,
        default: 100
    }
  },
  computed: {
    data() {
      return this.chartData;
    },
    options() {
      return {
        // color: "#FFFFFF",
        showDatapoints: true,
        responsive: true,
        maintainAspectRatio: false,
        // barValueSpacing: 1000,
        legend: {
          display: this.showLegend,
          labels: {
            fontColor: "white",
          },
        },
        title: {
          display: this.showTitle,
          text: this.chartData.datasets[0].label,
        },
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                fontColor: "white",
                display: true,
                max: this.maxValue,
              },
              gridLines: {
                display: true,
              },
            },
          ],
          xAxes: [
            {
              ticks: {
                beginAtZero: true,
                fontColor: "white",
                display: true,
              },
              gridLines: {
                display: false,
              },
            },
          ],
        },
      };
    },
  },
  mounted() {
    this.renderChart(this.chartData, this.options);
    // console.log(this.chartData)
  },
};
</script>
