<script>
import { Line, mixins } from "vue-chartjs";
const { reactiveProp } = mixins

export default {
  extends: Line,
    mixins: [reactiveProp],
    props: {
    chartData: {
      type: Object,
      default: null,
    },
    showTitle: {
      type: Boolean,
      default: false
    },
    showLegend: {
      type: Boolean,
      default: false
    },
    showYaxis: {
      type: Boolean,
      default: false
    },
    showXaxis: {
      type: Boolean,
      default: false
    }
  },
    computed: {
      options() {
        return {
          responsive: true,
          maintainAspectRatio: false,
          // lineTension: 70,
          legend: {
            display: this.showLegend,
          },
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                  fontColor: "white",
                  display: false,
                },
                gridLines: {
                  display: false,
                },
              },
            ],
            xAxes: [
              {
                ticks: {
                  display: true,
                  fontColor: "white",
                },
                gridLines: {
                  display: false,
                },
              },
            ],
          },
        };
      },
    

    // options: {
    //   type: Object,
    //   default: function () {
    //     return {
    //       responsive: true,
    //       maintainAspectRatio: false,
    //       legend: {
    //         display: false
    //       },
    //       scales: {
    //         yAxes: [{
    //           ticks: {
    //             beginAtZero: true,
    //             fontColor: 'white',
    //             display: false

    //           },
    //           gridLines: {
    //             display: false
    //           }
    //         }],
    //         xAxes: [{
    //           ticks: {
    //             fontColor: 'white'
    //           },
    //           gridLines: {
    //             display: false
    //           }
    //         }]

    //       }

    //     }
    //   }
    // }
  },
  mounted() {
    this.renderChart(this.chartData, this.options);
  },
};
</script>
