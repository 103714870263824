<template>
  <div v-if="wonAuctions && openAuctions">
    <section class="section">
      <!-- <b-button 
        @click="getSingleAuction('2857df999a784bd99de161999e9ed112')"
      >
        getsingleauction
      </b-button> -->
      <!-- <h1 class="title">Dashboard</h1> -->
      <div class="columns">
        <div
          v-for="tile in generalTiles"
          :key="tile.key"
          class="column is-4"
        >
          <router-link :to="'dashboard/' + tile.key">
            <Cardslot class="has-text-centered mouseover">
              <template #header>
                {{ tile.name }}
              </template>
              <template #iconheader>
                <b-icon
                  :icon="tile.icon"
                  type="is-light"
                  size="is-medium"
                />
              </template>
              <template #content>
                <div v-if="tile.key == 'openAuctions'">
                  <nav class="level">
                    <div class="level-item has-text-centered">
                      <div>
                        <p class="heading">
                          # open auctions
                        </p>
                        <p class="title">
                          <Animated-number :number="openAuctions.length" />
                        </p>
                      </div>
                    </div>

                    <div class="level-item has-text-centered">
                      <div>
                        <p class="heading">
                          # participating
                        </p>
                        <p class="title">
                          <Animated-number :number="0" />
                        </p>
                      </div>
                    </div>
                  </nav>
                  <Doughnut
                    style="height: 200px"
                    :chart-data="openChartData"
                  />
                  <div
                    v-for="auction in openAuctions"
                    :key="auction.auctionId"
                    class="column"
                  >
                    <div class="box">
                      {{ auction.auctionId }}
                    </div>
                    <!-- <div class="box">
                      auction 456 - carInsurance - not participating
                    </div> -->
                  </div>
                </div>
                <div v-if="tile.key == 'closedAuctions'">
                  <nav class="level">
                    <div class="level-item has-text-centered">
                      <div>
                        <p class="heading">
                          # closed auctions
                        </p>
                        <p class="title">
                          <Animated-number :number="480" />
                        </p>
                      </div>
                    </div>

                    <div class="level-item has-text-centered">
                      <div>
                        <p class="heading">
                          % won
                        </p>
                        <p class="title">
                          <Animated-number
                            :number="40"
                            :addon="'%'"
                          />
                        </p>
                      </div>
                    </div>
                  </nav>
                  <Doughnut
                    style="height: 200px"
                    :chart-data="closedChartData"
                  />
                  <Table
                    :input-data="tableData.slice(0, 5)"
                    :columns="['auctionId', 'product', 'winner']"
                    :narrowed="true"
                  />
                </div>
                <div v-if="tile.key == 'wonAuctions'">
                  <nav class="level">
                    <div class="level-item has-text-centered">
                      <div>
                        <p class="heading">
                          # auctions won
                        </p>
                        <p class="title">
                          <Animated-number :number="wonAuctions.length" />
                        </p>
                      </div>
                    </div>
                    <div class="level-item has-text-centered">
                      <div>
                        <p class="heading">
                          # in acceptation process
                        </p>
                        <p class="title">
                          <Animated-number :number="0" />
                        </p>
                      </div>
                    </div>
                  </nav>
                  <Horizontalbar
                    style="height: 180px"
                    :chart-data="barChartData"
                  />

                  <LineChart
                    style="height: 180px"
                    :chart-data="lineChartData"
                    :show-legend="false"
                  />
                </div>
              </template>
              <template #footer>
                {{ tile.footerText }}
              </template>
            </Cardslot>
          </router-link>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Cardslot from '../components/shared/cardslot.vue'
import LineChart from '../components/shared/charts/linechart.vue'
import Doughnut from '../components/shared/charts/doughnut.vue'
import Horizontalbar from '../components/shared/charts/horizontalbar.vue'
import AnimatedNumber from '../components/shared/animatedNumber.vue'
import Table from '../components/shared/charts/table.vue'

import { mapActions, mapGetters } from 'vuex'



export default {
  name: 'Dashboard',
  // mixins: [Bar],
  components: {
    Cardslot,
    LineChart,
    Doughnut,
    Horizontalbar,
    Table,
    AnimatedNumber
  },
  data: () => {
    return {
      generalTiles: [
        {
          key: 'openAuctions',
          name: 'Open auctions',
          icon: 'gavel',
          footerText: 'Participate and place your offers!'
        },
        {
          key: 'closedAuctions',
          name: 'Closed auctions',
          icon: 'list',
          footerText: 'All closed auctions, click to check your results!'
        },
        {
          key: 'wonAuctions',
          name: 'Won auctions',
          icon: 'user-plus',
          footerText: 'Your new clients, click to monitor their status here!'
        }
      ],
      lineChartData: {
        labels: ['January', 'February', 'March', 'April', 'May', 'June'],
        datasets: [
          {
            label: '# won clients trend',
            // backgroundColor: 'rgb(65, 184, 131)',
            data: [40, 20, 50, 25, 71, 104]
          }
        ]
      },
      barChartData: {
        labels: [
          'homeInsurance',
          'carInsurance',
          'contentInsurance',
          'travelInsurance',
          'petInsurance'
        ],
        datasets: [
          {
            label: '# won clients product',
            // backgroundColor: 'rgb(65, 184, 131)',
            data: [40, 35, 28, 25, 7, 5]
          }
        ]
      },
      closedChartData: {
        labels: ['Won', 'Lost'],
        datasets: [
          {
            label: '# Won auctions won',
            backgroundColor: ['rgb(65, 184, 131)', 'rgb(65, 184, 131, 0.2)'],
            borderColor: 'transparent',
            hoverOffset: 1,
            // backgroundColor: Object.values(Utils.CHART_COLORS),
            data: [192, 288]
          }
        ]
      },
      openChartData: {
        labels: ['Participating', 'not participating'],
        datasets: [
          {
            label: '# Auctions won',
            backgroundColor: ['rgb(65, 184, 131)', 'rgb(65, 184, 131, 0.2)'],
            borderColor: 'transparent',
            hoverOffset: 1,
            // backgroundColor: Object.values(Utils.CHART_COLORS),
            data: [0, 2]
          }
        ]
      },
      tableData: [
        {
          id: 1,
          auctionId: 'Auction123',
          product: 'homeInsurance',
          winner: 'NN',
          button: 'downloadunit'
        },
        {
          id: 2,
          auctionId: 'Auction321',
          product: 'homeInsurance',
          winner: 'NN'
        },
        {
          id: 3,
          auctionId: 'Auction121',
          product: 'travelInsurance',
          winner: 'Aegon'
        },
        {
          id: 4,
          auctionId: 'Auction312',
          product: 'carInsurance',
          winner: 'OHRA'
        },
        {
          id: 5,
          auctionId: 'Auction612',
          product: 'petInsurance',
          winner: 'AON'
        },
        {
          id: 3,
          auctionId: 'Auction121',
          product: 'travelInsurance',
          winner: 'Aegon'
        },
        {
          id: 4,
          auctionId: 'Auction312',
          product: 'carInsurance',
          winner: 'OHRA'
        },
        {
          id: 5,
          auctionId: 'Auction612',
          product: 'petInsurance',
          winner: 'AON'
        }
      ]
      // chartOptions: {
      //   title: {
      //     display: true,
      //     text: 'Chart.js Bar Chart',
      //     fontColor: 'white'
      //   },
      //   legend: {
      //     display: false
      //   }

      // }
    }
  },
  computed: {
    ...mapGetters(["openAuctions", "wonAuctions"])
    // myStyles () {
    //   return {
    //     // height: '300px',
    //     position: 'relative'
    //   }
    // }
  },
  mounted () {
        if(!this.wonAuctions) {
      this.getWonAuctions()
    }
    if(!this.openAuctions) {
      this.getOpenAuctions()
    }
    // this.renderChart(this.chartdata, this.options)
  },
  methods: {
    ...mapActions(["getWonAuctions", "getOpenAuctions", "getSingleAuction"])
  }
}
</script>

<style lang="scss" scoped>
.chart-container {
  position: relative;
  margin: auto;
}
// .chart {
//   height: 20px
// }

// .footer {
//   height: 1px
// }
// .box-footer {
//   position: sticky;
//   top: 0;
//   width: 100%;
//   background-color: $primary;
//   // bottom: 0px;
// }
</style>
