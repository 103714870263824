<template>
  <section class="section">
    <h1 class="title">
      Open Auctions
    </h1>

    <!-- {{ openAuctions }} -->
    <div class="columns">
      <div class="column is-4">
        <ul>
          <transition-group
            appear
            name="fade"
          >
            <li
              v-for="(auction, index) in openAuctions"
              :key="auction.auctionId"
              class="box mouseover"
              :class="{ green: auctionChoice.auctionId == auction.auctionId }"
              :style="{ '--index': index }"
              @click="setAuction(auction)"
            >
              <nav class="level">
                <div class="level-left">
                  <!-- <div class="level-item">
                    {{ auction.auctionId }}
                  </div> -->
                  <div class="level-item">
                    {{ auction.auctionId.slice(0, 5) }} - {{ auction.pid }}
                  </div>
                </div>

                <div
                  v-show="auctionChoice.auctionId == auction.auctionId"
                  class="level-right"
                >
                  <div class="level-item">
                    <b-icon icon="chevron-right" />
                  </div>
                </div>
              </nav>
            </li>
          </transition-group>
        </ul>
      </div>
      <div class="column">
        <Auction :auction="auctionChoice" />
      </div>
    </div>
    <div class="has-text-left">
      <router-link
        class="is-italic"
        to="/dashboard"
      >
        <b-icon
          icon="chevron-left"
          @click.native="$router.push('/dashboard')"
        />
        Dashboard
      </router-link>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Auction from "../components/auction.vue";
export default {
  name: "OpenAuctions",
  components: {
    Auction,
  },
  data: () => {
    return {
      auctionChoice: {},
      // openAuctions: [
      //   {
      //     auctionId: "lkjsdf40978af084fc7a0fc468a34ca4797",
      //     pid: "homeInsurance",
      //     request: {
      //       houseinfoApi: {
      //         houseinfo: {},
      //       },
      //       passportApi: {
      //         valid: true,
      //         age: 70,
      //       },
      //       passportUser: {
      //         age: 30,
      //       },
      //       facecomparison: {
      //         data: {
      //           tolerance: 0.6,
      //           facematch: {
      //             similarity: 98.59278869628906,
      //           },
      //         },
      //       },
      //       timestamp: 1626255389,
      //       pid: "3eaf347262364a169fba9113e82f1f14",
      //       nonce: "6033a5d99821480cb9a0e0900d119646",
      //     },
      //     crton: "2021-07-14T09:37:20.834Z",
      //     biddingTime: 10000,
      //     auctionEnd: "2021-07-15T10:47:20.834Z",
      //     auctionResult: {
      //       auctionEndTime: "1626255959",
      //       auctionChecksum:
      //         "0x94e86cf59357b073171d189c094d418e4328a6c4888fd112653ebb473c738f27",
      //       lowestBid: "0",
      //       minDecrease: "1",
      //       lowestBidSet: false,
      //       auctionEnded: false,
      //       lowestBidder: "0x0000000000000000000000000000000000000000",
      //     },
      //     lowestBidSetByThisAccount: false,
      //   },
      //   {
      //     auctionId: "058570d640978afsdfsdfsdfa34ca4797",
      //     pid: "travelInsurance",
      //     request: {
      //       houseinfoApi: {
      //         houseinfo: {},
      //       },
      //       passportApi: {
      //         valid: true,
      //         age: 30,
      //       },
      //       passportUser: {
      //         age: 30,
      //       },
      //       facecomparison: {
      //         data: {
      //           tolerance: 0.6,
      //           facematch: {
      //             similarity: 98.59278869628906,
      //           },
      //         },
      //       },
      //       timestamp: 1626255389,
      //       pid: "3eaf347262364a169fba9113e82f1f14",
      //       nonce: "6033a5d99821480cb9a0e0900d119646",
      //     },
      //     crton: "2021-07-14T09:37:20.834Z",
      //     biddingTime: 97000,
      //     auctionEnd: "2021-07-15T10:47:20.834Z",
      //     auctionResult: {
      //       auctionEndTime: "1626255959",
      //       auctionChecksum:
      //         "0x94e86cf59357b073171d189c094d418e4328a6c4888fd112653ebb473c738f27",
      //       lowestBid: "0",
      //       minDecrease: "1",
      //       lowestBidSet: false,
      //       auctionEnded: false,
      //       lowestBidder: "0x0000000000000000000000000000000000000000",
      //     },
      //     lowestBidSetByThisAccount: false,
      //   },
      //   {
      //     auctionId: "845870d640978afsdfsdfsdfa34ca4797",
      //     pid: "travelInsurance",
      //     request: {
      //       houseinfoApi: {
      //         houseinfo: {},
      //       },
      //       passportApi: {
      //         valid: true,
      //         age: 30,
      //       },
      //       passportUser: {
      //         age: 30,
      //       },
      //       facecomparison: {
      //         data: {
      //           tolerance: 0.6,
      //           facematch: {
      //             similarity: 98.59278869628906,
      //           },
      //         },
      //       },
      //       timestamp: 1626255389,
      //       pid: "3eaf347262364a169fba9113e82f1f14",
      //       nonce: "6033a5d99821480cb9a0e0900d119646",
      //     },
      //     crton: "2021-07-14T09:37:20.834Z",
      //     biddingTime: 11500,
      //     auctionEnd: "2021-07-15T10:47:20.834Z",
      //     auctionResult: {
      //       auctionEndTime: "1626255959",
      //       auctionChecksum:
      //         "0x94e86cf59357b073171d189c094d418e4328a6c4888fd112653ebb473c738f27",
      //       lowestBid: "0",
      //       minDecrease: "1",
      //       lowestBidSet: false,
      //       auctionEnded: false,
      //       lowestBidder: "0x0000000000000000000000000000000000000000",
      //     },
      //     lowestBidSetByThisAccount: false,
      //   },
      //   {
      //     auctionId: "242340d640978afsdfsdfsdfa34ca4797",
      //     pid: "travelInsurance",
      //     request: {
      //       houseinfoApi: {
      //         houseinfo: {},
      //       },
      //       passportApi: {
      //         valid: true,
      //         age: 30,
      //       },
      //       passportUser: {
      //         age: 30,
      //       },
      //       facecomparison: {
      //         data: {
      //           tolerance: 0.6,
      //           facematch: {
      //             similarity: 98.59278869628906,
      //           },
      //         },
      //       },
      //       timestamp: 1626255389,
      //       pid: "3eaf347262364a169fba9113e82f1f14",
      //       nonce: "6033a5d99821480cb9a0e0900d119646",
      //     },
      //     crton: "2021-07-14T09:37:20.834Z",
      //     biddingTime: 12000,
      //     auctionEnd: "2021-07-15T10:41:20.834Z",
      //     auctionResult: {
      //       auctionEndTime: "1626255959",
      //       auctionChecksum:
      //         "0x94e86cf59357b073171d189c094d418e4328a6c4888fd112653ebb473c738f27",
      //       lowestBid: "0",
      //       minDecrease: "1",
      //       lowestBidSet: false,
      //       auctionEnded: false,
      //       lowestBidder: "0x0000000000000000000000000000000000000000",
      //     },
      //     lowestBidSetByThisAccount: false,
      //   },
      // ],
      // openAuctions: [
      //   { auctionId: 'AuctionXyz123', product: 'HomeInsurance' },
      //   { auctionId: 'AuctionXyz456', product: 'TravelInsurance' },
      //   { auctionId: 'AuctionXyz894', product: 'CarInsurance' },
      //   { auctionId: 'AuctionXyz749', product: 'PetInsurance' },
      //   { auctionId: 'AuctionXyz659', product: 'CarInsurance' },
      //   { auctionId: 'AuctionXyz184', product: 'ContentsInsurance' }
      //   // { id: 'AuctionXyz655', product: 'CarInsurance' }
      // ]
    };
  },
  computed: {
    ...mapGetters(["openAuctions"]),
  },
  mounted() {
    if (!this.openAuctions) {
      this.getOpenAuctions();
    }
    // this.renderChart(this.chartdata, this.options)
  },
  methods: {
    ...mapActions(["getOpenAuctions"]),
    setAuction(auction) {
      // set AuctionID
      this.auctionChoice = {};
      //  this.auctionChoice = auction;
      setTimeout(() => {
        this.auctionChoice = auction;
      }, 100);
      // this.auctionChoice = auctionId
      // close current AuctionComponent
      // open new AuctionComponent with auctionId
    },
  },
};
</script>

<style lang="scss" scoped>
.green {
  background-color: $link;
}

.mouseover:hover {
  cursor: pointer;
}
.show {
  &-enter,
  &-leave-to {
    opacity: 0;
    transform: translateX(60px);
  }
  &-enter-active,
  &-leave-active {
    transition: all 200ms;
  }
}
.fade {
  &-enter {
    opacity: 0;
    transform: translateX(60px);
  }
  &-enter-active {
    transition: all 200ms ease-in-out;
    transition-delay: calc(50ms * var(--index));
  }
}

// a {
//   color: $light
// }
</style>
