<script>
import { HorizontalBar, mixins } from "vue-chartjs";

export default {
  extends: HorizontalBar,
  // mixins: [mixins.reactiveProp],
  mixins: [mixins.reactiveProp],
  props: {
    chartData: {
      type: Object,
      default: null,
    },
    showTitle: {
      type: Boolean,
      default: false
    },
    showLegend: {
      type: Boolean,
      default: false
    }
  },
  // data: () => {
  //   return {
  //     options: {
  //       color: "#FFFFFF",
  //       showDatapoints: true,
  //       responsive: true,
  //       maintainAspectRatio: false,
  //       //   type: 'horizontalBar',
  //       legend: {
  //         display: true,
  //         labels: {
  //           fontColor: "white",
  //         },
  //       },
  //       title: {
  //         display: this.showTitle
  //       },
  //       scales: {
  //         yAxes: [
  //           {
  //             ticks: {
  //               beginAtZero: true,
  //               fontColor: "white",
  //               display: true,
  //             },
  //             gridLines: {
  //               display: false,
  //             },
  //           },
  //         ],
  //         xAxes: [
  //           {
  //             ticks: {
  //               fontColor: "white",
  //               display: false,
  //             },
  //             gridLines: {
  //               display: false,
  //             },
  //           },
  //         ],
  //       },
  //     },
  //   };
  // },
  computed: {
    data() {
      return this.chartData
    },
    options() {
      return {
        // color: "#FFFFFF",
        showDatapoints: true,
        responsive: true,
        maintainAspectRatio: false,
        //   type: 'horizontalBar',
        legend: {
          display: this.showLegend,
          labels: {
            fontColor: "white",
          },
        },
        title: {
          display: this.showTitle,
          text: this.chartData.datasets[0].label
        },
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                fontColor: "white",
                display: true,
              },
              gridLines: {
                display: false,
              },
            },
          ],
          xAxes: [
            {
              ticks: {
                beginAtZero: true,
                fontColor: "white",
                display: false,
              },
              gridLines: {
                display: false,
              },
            },
          ],
        },
    }}
  },
  mounted() {
    this.renderChart(this.chartData, this.options);
    // console.log(this.chartData)
  },
};
</script>
