<template>
  <section
    v-if="!isAuthenticated"
    class="section"
  >
    <div class="column is-half is-offset-one-quarter">
      <h1 class="title">
        Sign in
      </h1>
      <div class="box">
        <form @submit.prevent="postLogin(loginData)">
          <b-field label="API key">
            <b-input
              v-model="loginData.apikey"
              type="password"
            />
          </b-field>
          <b-field label="API secret">
            <b-input
              v-model="loginData.apisecret"
              type="password"
            />
          </b-field>
          <b-button
            native-type="submit"
            type="is-link"
          >
            Sign in
          </b-button>
        </form>
      </div>
    </div>
  </section>
  <!-- <section
    v-else
    class="section has-text-centered"
  >
    <h1 class="title">
      Welcome insurer
    </h1>
    <h2 class="subtitle">
      Please navigate with help of the left sidebar
    </h2>
  </section> -->
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      loginData: {
        apikey: "f64068e3c0fb470aa851e706318f3684",
        apisecret: "4a7222e8b269473ca038024c8b988c24",
      },
    };
  },
  computed: {
    ...mapGetters(["isAuthenticated"])
  },
  methods: {
    ...mapActions(["postLogin"]),
  },
};
</script>

<style lang="scss" scoped>
</style>
