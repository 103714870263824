<template>
  <section class="section container">
    <h1 class="title">
      Releases
    </h1>
    <div class="box featurebar">
      <div class="columns">
        <div class="column">
          <h1 class="title">
            v0.2
          </h1>
        </div>
        <div class="column has-text-right">
          <p class="">
            12-08-2021
          </p>
        </div>
      </div>
      
      <h2 class="subtitle">
        New features
      </h2>
      <ul>
        <li>Pricing analytics module</li>
        <li>Market analytics module</li>
        <li>NFT exchange module</li>
      </ul>
      <br>
      <h2 class="subtitle">
        Fixes
      </h2>
      <ul>
        <li>Open auction timer</li>
        <li>Won auction deepdive modal</li>
      </ul>
    </div>
    <div class="box featurebar">
      <div class="columns">
        <div class="column">
          <h1 class="title">
            v0.1
          </h1>
        </div>
        <div class="column has-text-right">
          <p class="">
            19-07-2021
          </p>
        </div>
      </div>
      
      <h2 class="subtitle">
        New features
      </h2>
      <ul>
        <li>Initial release</li>
      </ul>
      <!-- <br>
      <h2 class="subtitle">
        Fixes
      </h2> -->
    </div>
  </section>
</template>

<script>
export default {
name: 'Releases'
}
</script>

<style lang="scss">
.featurebar {
    border-left: 2px solid $link;;
}
ul {
  list-style-type: circle;
  list-style-position: inside;
}
</style>