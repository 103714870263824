<template>
  <section class="section">
    <!-- <div class="title has-text-centered">
      Pricing Analytics
    </div> -->
    <!-- <div class="title">
      Analytics /  Pricing Analytics
    </div>  -->
    <nav
      class="breadcrumb is-medium"
      aria-label="breadcrumbs"
    >
      <ul>
        <li class="is-size-3">
          <router-link
            
            to="/analytics"
          >
            Analytics
          </router-link>
        </li>
        <li class="is-active is-size-3">
          <router-link
            
            to="/analytics/pricingAnalytics"
          >
            Pricing Analytics
          </router-link>
        </li>
      </ul>
    </nav>
    <div class="columns is-multiline">
      <div class="column has-text-centered is-2 ">
        <div class="box">
          <div class="block">
            <b-icon
              icon="filter"
              size="is-large"
            />
          </div>
         
          <!-- <div class="subtitle">
            Filters
          </div> -->
          <b-field label="products">
            <b-select
              v-model="selectedProducts"
              multiple
              size="is-small"
              @input="filter()"
            >
              <option
                v-for="product in productValues"
                :key="product"
                :value="product"
              >
                {{ product }}
              </option>
            </b-select>
          </b-field>

          <b-field label="clientSegments">
            <b-select
              v-model="selectedClients"
              multiple
              size="is-small"
              @input="filter()"
            >
              <option
                v-for="clientSegment in clientValues"
                :key="clientSegment"
                :value="clientSegment"
              >
                {{ clientSegment }}
              </option>
            </b-select>
          </b-field>
          <b-field label="Months">
            <b-select
              v-model="selectedMonths"
              multiple
              size="is-small"
              @input="filter()"
            >
              <option
                v-for="date in dateValues"
                :key="date"
                :value="date"
              >
                {{ date }}
              </option>
            </b-select>
          </b-field>
          <br>
          <a @click="clearAllFilters()">
        
            <b-icon
              icon="times-circle"
              size="is-medium"
              type="is-link"
            />  <p>clear filters</p></a>
        </div>
      </div>
      <!-- {{ myOfferPerProduct }} -->
      <div class="column is-10">
        <div class="columns is-multiline">
          <!-- <div class="column is-half has-text-centered">
            <div class="box">
              <div class="subtitle">
                My pricing vs winner
              </div>
              <Horizontalbar
                ref="barChart"
                style="height: 250px"
                :chart-data="horizontalBarData"
              />
            </div>
          </div> -->
          <div class="column is-half has-text-centered">
            <div class="box">
              <div class="subtitle">
                Percentage winning offer
              </div>
              <nav class="level">
                <div class="level-item has-text-centered">
                  <div>
                    <p class="heading">
                      # closed auctions
                    </p>
                    <p class="title">
                      <Animated-number :number="totalAuctions" />
                    </p>
                  </div>
                </div>

                <div class="level-item has-text-centered">
                  <div>
                    <p class="heading">
                      % won
                    </p> 
                    <p class="title">
                      <Animated-number
                        :number="Math.floor((auctionsWon / totalAuctions) * 100)"
                        :addon="'%'"
                      />
                    </p>
                  </div>
                </div>
              </nav>
              <Doughnut
                style="height: 180px"
                :chart-data="doughnutData"
              />
            </div>
          </div>
          <div class="column is-half has-text-centered">
            <div class="box ">
              <div class="subtitle">
                My offer vs winner over time
              </div>
              <p
                v-if="!selectedProducts.length"
                style="height: 200px"
                class="is-italic"
              >
                We advice to first filter on product before analyzing offfer trends
              </p>
              <LineChart
                v-else
                ref="lineChart"
                style="height: 250px"
                show-legend
                :chart-data="lineData"
              />
            </div>
          </div>

          <div class="column is-half has-text-centered">
            <div class="box">
              <div class="subtitle">
                My offer vs winner per product
              </div>
              <BarChart
                show-legend
                style="height: 250px"
                :chart-data="productBarData"
                :max-value="maxOffer"
              />
              <!-- <RadarChart
                ref="labelChart"
                style="height: 300px"
                show-legend
                :chart-data="radarProductData"
              /> -->
            </div>
          </div>
          <div class="column is-half has-text-centered">
            <div class="box">
              <div class="subtitle">
                My offer vs winner per client segment
              </div>
              <BarChart
                show-legend
                style="height: 250px"
                :chart-data="clientBarData"
                :max-value="maxOffer"
              />
              <!-- <RadarChart
                ref="labelChart"
                style="height: 300px"
                show-legend
                :chart-data="radarProductData"
              /> -->
            </div>
            <!-- <div class="box">
              <div class="subtitle">
                My pricing vs winner per client segment
              </div>
              <RadarChart
                ref="radarChart"
                style="height: 300px"
                show-legend
                :chart-data="radarClientData"
              />
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="has-text-left">
      <router-link
        class="is-italic"
        to="/analytics"
      >
        <b-icon
          icon="chevron-left"
          @click.native="$router.push('/analytics')"
        />
        Analytics
      </router-link>
    </div> -->
  </section>
</template>

<script>
// import Horizontalbar from "../components/shared/charts/horizontalbar.vue";
import LineChart from "../components/shared/charts/linechart.vue";
// import RadarChart from "../components/shared/charts/radarchart.vue";
import BarChart from "../components/shared/charts/barchart.vue";
import Doughnut from '../components/shared/charts/doughnut.vue';
import AnimatedNumber from '../components/shared/animatedNumber.vue'

import { mapGetters } from "vuex";

export default {
  components: {
    // Horizontalbar,
    LineChart,
    // RadarChart,
    BarChart,
    Doughnut,
    AnimatedNumber
  },
  data: () => {
    return {
      selectedClients: [],
      selectedProducts: [],
      selectedMonths: [],
      sourceData: null,
      winningOfferColor: "rgb(65, 184, 131)",
      myOfferColor: '#003049',
    };
  },
  computed: {
    ...mapGetters(["APIsourceData"]),
    selection() {
      return this.selectedClients.concat(this.selectedProducts);
    },
    myOfferAvg() {
      return (
        this.sourceData.reduce((acc, auction) => acc + auction.myOffer, 0) /
        this.sourceData.length
      );
    },
    winningOfferAvg() {
      return (
        this.sourceData.reduce(
          (acc, auction) => acc + auction.winningOffer,
          0
        ) / this.sourceData.length
      );
    },
    bothOfferLabels() {
      return Object.keys(this.sourceData[0]).filter(
        (name) => name.indexOf("Offer") !== -1
      );
    },
    myOfferLabel() {
      // return Object.keys(this.sourceData[0])
      //   .filter((name) => name.includes(["myOffer"]))
      //   .toString();
        return "myOffer (avg)"
    },
    winningOfferLabel() {
      // return Object.keys(this.sourceData[0])
      //   .filter((name) => name.includes(["winningOffer"]))
      //   .toString();
      return "winningOffer (avg)"
    },
    APIproductValues() {
      const products = this.APIsourceData.map(function (el) {
        return el.product;
      });
      let unique = [...new Set(products)];
      return unique;
    },
    APIclientValues() {
      const clientSegments = this.APIsourceData.map(function (el) {
        return el.clientSegment;
      });
      let unique = [...new Set(clientSegments)];
      return unique;
    },
    APIdateValues() {
      const dates = this.APIsourceData.map(function (el) {
        return el.date;
      });
      let unique = [...new Set(dates)];
      return unique;
    },
    productValues() {
      const products = this.sourceData.map(function (el) {
        return el.product;
      });
      let unique = [...new Set(products)];
      return unique;
    },
    clientValues() {
      const clientSegments = this.sourceData.map(function (el) {
        return el.clientSegment;
      });
      let unique = [...new Set(clientSegments)];
      return unique;
    },
    dateValues() {
      const date = this.sourceData.map(function (el) {
        return el.date;
      });
      let unique = [...new Set(date)];
      return unique;
    },
    winningOfferPerProduct() {
      const mapped = this.productValues.map((product) => {
        return (
          this.sourceData
            .filter(function (pilot) {
              return pilot.product === product;
            })
            .reduce((acc, auction) => acc + auction.winningOffer, 0) /
          this.sourceData.filter(function (auction) {
            return auction.product === product;
          }).length
        );
      });
      return mapped;
    },
    myOfferPerProduct() {
      const mapped = this.productValues.map((product) => {
        return (
          this.sourceData
            .filter(function (pilot) {
              return pilot.product === product;
            })
            .reduce((acc, auction) => acc + auction.myOffer, 0) /
          this.sourceData.filter(function (auction) {
            return auction.product === product;
          }).length
        );
      });
      return mapped;
    },
    myOfferPerClientSegment() {
      const mapped = this.clientValues.map((clientSegment) => {
        return (
          this.sourceData
            .filter(function (pilot) {
              return pilot.clientSegment === clientSegment;
            })
            .reduce((acc, auction) => acc + auction.myOffer, 0) /
          this.sourceData.filter(function (auction) {
            return auction.clientSegment === clientSegment;
          }).length
        );
      });
      return mapped;
    },
    winningOfferPerClientSegment() {
      const mapped = this.clientValues.map((clientSegment) => {
        return (
          this.sourceData
            .filter(function (pilot) {
              return pilot.clientSegment === clientSegment;
            })
            .reduce((acc, auction) => acc + auction.winningOffer, 0) /
          this.sourceData.filter(function (auction) {
            return auction.clientSegment === clientSegment;
          }).length
        );
      });
      return mapped;
    },
    winningOfferPerMonth() {
      const mapped = this.dateValues.map((date) => {
        return (
          this.sourceData
            .filter(function (elm) {
              return elm.date === date;
            })
            .reduce((acc, auction) => acc + auction.winningOffer, 0) /
          this.sourceData.filter(function (auction) {
            return auction.date === date;
          }).length
        );
      });
      return mapped;
    },
    myOfferPerMonth() {
      const mapped = this.dateValues.map((date) => {
        return (
          this.sourceData
            .filter(function (elm) {
              return elm.date === date;
            })
            .reduce((acc, auction) => acc + auction.myOffer, 0) /
          this.sourceData.filter(function (auction) {
            return auction.date === date;
          }).length
        );
      });
      return mapped;
    },
    totalAuctions() {
      return this.sourceData.length
    },
    auctionsWon() {
      return this.sourceData.filter((x) => x.winningOffer == x.myOffer).length
    },
    maxOffer() {
      return Math.max.apply(Math, this.sourceData.map(function(o) { return o.myOffer; }))
    },
    /////////////////////////////////////////////// CHARTS ///////////////////////////////////////////////
    productBarData() {
      return {
        labels: this.productValues,
        // labels: ["contents", "car", "travel", "home"],
        datasets: [
          {
            label: this.winningOfferLabel,
            backgroundColor: this.winningOfferColor,
            data: this.winningOfferPerProduct,
            categoryPercentage: 0.5,
          },
          {
            label: this.myOfferLabel,
            backgroundColor: this.myOfferColor,
            data: this.myOfferPerProduct,
            categoryPercentage: 0.5,
          },
        ],
      };
    },
        clientBarData() {
      return {
        labels: this.clientValues,
        // labels: ["contents", "car", "travel", "home"],
        datasets: [
          {
            label: this.winningOfferLabel,
            backgroundColor: this.winningOfferColor,
            data: this.winningOfferPerClientSegment,
            categoryPercentage: 0.5,
          },
          {
            label: this.myOfferLabel,
            backgroundColor: this.myOfferColor,
            data: this.myOfferPerClientSegment,
            categoryPercentage: 0.5,
          },
        ],
      };
    },
    lineData() {
      return {
        labels: ['January', 'Feb'],
        datasets: [
          //           {
          //   label: 'winningOffer',
          //   borderColor: this.winningOfferColor,
          //   data: this.sourceData,
          //   parsing: {
          //     yAxisKey: 'winningOffer'
          //   },
          //   fill: false,
          //   //  cubicInterpolationMode: 'default'
          // },
          {
            label: this.winningOfferLabel,
            borderColor: this.winningOfferColor,
            data: this.winningOfferPerMonth.filter(Number),
            fill: false,
            //  cubicInterpolationMode: 'default'
          },
          {
            label: this.myOfferLabel,
            backgroundColor: this.myOfferColor,
            data: this.myOfferPerMonth.filter(Number),
            fill: false,
          },
        ],
      };
    },
    radarProductData() {
      return {
        labels: this.productValues,
        datasets: [
          {
            label: this.winningOfferLabel,
            borderColor: this.winningOfferColor,
            data: this.winningOfferPerProduct.filter(Number),
            fill: false,
          },
          {
            label: this.myOfferLabel,
            borderColor: this.myOfferColor,
            data: this.myOfferPerProduct.filter(Number),
            fill: false,
          },
        ],
      };
    },
    doughnutData() {
      return {
        labels: ['Won', 'Lost'],
        datasets: [
          {
            label: '# Won auctions won',
            backgroundColor: ['rgb(65, 184, 131)', 'rgb(65, 184, 131, 0.2)'],
            borderColor: 'transparent',
            hoverOffset: 1,
            // backgroundColor: Object.values(Utils.CHART_COLORS),
            data: [this.auctionsWon, this.totalAuctions - this.auctionsWon]
          }
        ]
      };
    },
    radarClientData() {
      return {
        labels: this.clientValues,
        datasets: [
          {
            label: this.winningOfferLabel,
            borderColor: this.winningOfferColor,
            data: this.winningOfferPerClientSegment.filter(Number),
            fill: true,
          },
          {
            label: this.myOfferLabel,
            borderColor: this.myOfferColor,
            data: this.myOfferPerClientSegment.filter(Number),
          },
        ],
      };
    },
  },

  created() {
    // console.log(tgus,)
    this.sourceData = this.APIsourceData;
    // or `this.selectedOption = this.currentLesson` if you keep that mapped getter
  },
  methods: {
    filter() {
      if (!this.selectedProducts.length) {
        this.selectedProducts = this.APIproductValues;
      }
      if (!this.selectedClients.length) {
        this.selectedClients = this.APIclientValues;
      }
      if (!this.selectedMonths.length) {
        this.selectedMonths = this.APIdateValues;
      }
      this.sourceData = this.APIsourceData.filter(
        (el) =>
          this.selectedClients.indexOf(el.clientSegment) >= 0 &&
          this.selectedProducts.indexOf(el.product) >= 0 &&
          this.selectedMonths.indexOf(el.date) >= 0
      );
    },
    clearAllFilters() {
      this.selectedProducts = this.APIproductValues;
      this.selectedClients = this.APIclientValues;
      this.selectedMonths = this.APIdateValues;

      this.filter();
    },
  },
};
</script>

<style>

</style>