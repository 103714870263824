<template>
  <div>
    <section class="section">
      <!-- <h1 class="title">Analytics</h1> -->
      <div class="columns is-multiline">
        <div
          v-for="tile in additionalTiles"
          :key="tile.key"
          class="column is-4"
        >
          <router-link :to="'analytics/' + tile.key">
            <Cardslot class="has-text-centered mouseover">
              <template #header>
                {{ tile.name }}
              </template>
              <template #content>
                <b-icon
                  :icon="tile.icon"
                  size="is-large"
                />
              </template>
              <template #footer>
                {{ tile.footerText }}
              </template>
            </Cardslot>
          </router-link>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Cardslot from '../components/shared/cardslot.vue'

export default {
  name: 'Dashboard',
  components: {
    Cardslot
  },
  data: () => {
    return {
      additionalTiles: [
        {
          key: 'pricingAnalytics',
          name: 'Pricing analytics',
          icon: 'chart-line',
          footerText: 'Analyse and improve your pricing strategy'
        },
        {
          key: 'marketAnalytics',
          name: 'Market analytics',
          icon: 'chart-bar',
          footerText: 'Analyze the market dynamics and apply this in auctions'
        },
        {
          key: 'nftExchange',
          name: 'NFT exchange',
          icon: 'exchange-alt',
          footerText: 'Exchange your clients'
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
// .footer {
//   height: 1px
// }
// .box-footer {
//   position: sticky;
//   top: 0;
//   width: 100%;
//   background-color: $primary;
//   // bottom: 0px;
// }
</style>
