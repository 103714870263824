<template>
  <transition name="show">
    <div
      v-if="auction.auctionId"
      class="box has-text-centered"
    >
      <h1 class="title">
        {{ auction.pid }}
      </h1>
      <div class="columns">
        <div class="column">
          <h1 class="subtitle">
            Timer
          </h1>
          <!-- {{ parseInt(auction.biddingTime) }} -->
          <!-- {{ auction.endDate }} -->
          <!-- <Auctiontimer

            :end-date="auction.auctionEnd"
            :timelimit="auction.biddingTime"
          /> -->
          <!-- {{ Math.floor((new Date(auction.auctionEnd) - new Date()) / 1000) }} -->
          <Auctiontimer
            :time-left-prop="auctionTimeLeft(auction.auctionEnd)"
            :timelimit="auction.biddingTime"
          />
          <!-- <h1 class="is-size-6 is-italic">Opstalverzekering</h1> -->
        </div>
        <div class="column">
          <div class="subtitle">
            Offers
          </div>
          <Table
            sortable
            :input-data="tableData"
            :columns="['offer', 'insurer']"
          />
          <!-- <div class="column">
            <div class="box">
              <p style="font-size: 0.7em">
                10EUR - NN
              </p>
            </div>
            <div class="box">
              <p style="font-size: 0.7em">
                13EUR - AEGON
              </p>
            </div>
            <div class="box">
              <p style="font-size: 0.7em">
                14EUR - OHRA
              </p>
            </div>
          </div> -->
        </div>
        <div class="column">
          <h1 class="subtitle">
            Participation
          </h1>
          <div class="column has-text-centered">
            <b-field
              class="has-text-left"
              label="My offer"
            >
              <b-numberinput
                v-model="offer"
                type="is-link"
              />
            </b-field>

            <b-field
              class="has-text-left"
              label="Confirm offer"
            >
              <b-numberinput
                v-model="confirmedOffer"
                type="is-link"
              />
            </b-field>
            <b-button
              :disabled="offer != confirmedOffer"
              icon-left="check"
              expanded
              type="is-primary"
            >
              Place offer
            </b-button>
          </div>
        </div>
      </div>
      <!-- {{ auction }} -->
      <!-- {{ filteredObject(auction.request, ['pid', 'houseinfoApi']) }} -->
      <b-tabs expanded>
        <b-tab-item
          label="Request data"
          icon="home"
        >
          <div class="has-text-left box">
            <tree-view
              :data="filteredObject(auction.request, [ 'houseinfoApi', 'houseinfoUser'])"
              :options="{modifiable: false, link: true}"
            />
            <!-- <tree-view
              :data="filteredObject(auction.request, [ 'houseinfoApi'])"
              :options="{modifiable: false, link: true}"
            /> -->
            <!-- <ul class="box">
              <li
                v-for="(value, name) in filteredObject(auction.request, [ 'houseinfoApi'])"
                :key="value.id"
              >
                {{ name }}: {{ value }}
              </li>
            </ul> -->
            <!-- <ul class="box">
              <li
                v-for="(value, name) in filteredObject(auction.request, [ 'houseinfoApi'])"
                :key="value.id"
                class=""
              >
                <div class="columns">
                  <div class="column is-3">
                    {{ name }}:
                  </div>
                  <div
                    v-if="typeof value === 'object'"
                    class="column"
                  >
                    <ul>
                      <li
                        v-for="(valueN, nameN) in value"
                        :key="valueN.id"
                      >
                        <div class="columns">
                          <div class="column">
                            {{ nameN }}
                          </div>
                          <div
                            v-if="typeof valueN === 'object' "
                            class="column"
                          >
                            <ul>
                              <li 
                                v-for="(valueNN, nameNN) in valueN"
                                :key="valueNN.id"
                              >
                                <div class="columns">
                                  <div class="column">
                                    {{ nameNN }}:
                                  </div><div class="column">
                                    {{ valueNN }}
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                          <div
                            v-else
                            class="column"
                          >
                            {{ valueN }}
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div
                    v-else
                    class="column"
                  >
                    {{ value }}
                  </div>
                </div>
              </li>
            </ul> -->
          </div>
        </b-tab-item>
        <b-tab-item
          label="Client data"
          icon="user"
        >
          <div class="has-text-left box">
            <tree-view
              :data="filteredObject(auction.request, [ 'passportApi', 'passportUser'])"
              :options="{modifiable: false, link: true}"
            />
            <!-- <ul class="box">
              <li
                v-for="(value, name) in filteredObject(auction.request, [ 'passportApi', 'passportUser'])"
                :key="name.id"
              >
                <div class="columns">
                  <div class="column is-3">
                    {{ name }}:
                  </div>

                  <div
                    v-if="typeof value === 'object'"
                    class="column"
                  >
                    <ul>
                      <li
                        v-for="(valueN, nameN) in value"
                        :key="valueN.id"
                      >
                        <div class="columns">
                          <div class="column is-3">
                            {{ nameN }}
                          </div>
                          <div class="column is-3">
                            {{ valueN }}
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div
                    v-else
                    class="column"
                  >
                    {{ value }}
                  </div>
                </div>
              </li>
            </ul> -->
          </div>
        </b-tab-item>

        <b-tab-item
          label="Auction data"
          icon="gavel"
        >
          <div class="has-text-left box">
            <tree-view
              :data="auction.auctionResult"
              :options="{modifiable: false, link: true, rootObjectKey: auction.auctionId}"
            />
          </div>
          <!-- <div class="has-text-left">
            <ul class="box">
              <li
                v-for="(value, name) in auction.auctionResult"
                :key="value.id"
              >
                <div class="columns">
                  <div class="column is-3">
                    {{ name }} :
                  </div>
                  <div class="column">
                    {{ value }}
                  </div>
                </div>
              </li>
            </ul>
          </div> -->
        </b-tab-item>
        <b-tab-item
          label="Pricing analytics"
          icon="chart-line"
        >
          <div class="has-text-left">
            Pricing placeholder
          </div>
        </b-tab-item>
      </b-tabs>
      <div class="has-text-right">
        <b-button
          icon-left="times"
          type="is-warning "
          outlined
          inverted
        >
          Reject
        </b-button>
      </div>
    </div>
  </transition>
</template>

<script>
import Auctiontimer from "./shared/auctiontimer.vue";
import Table from "./shared/charts/table";

export default {
  name: "Auction",
  components: {
    Auctiontimer,
    Table,
  },

  props: {
    auction: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      offer: 14,
      confirmedOffer: 12,
      tableData: [
        { offerId: "xyz", offer: 13, insurer: "NN" },
        { offerId: "xdz", offer: 12, insurer: "AEGON" },
        { offerId: "xaz", offer: 14, insurer: "OHRA" },
        { offerId: "xyz", offer: 19, insurer: "AON" },
        { offerId: "xdz", offer: 9, insurer: "Centraal beheer" },
      ],
    };
  },
  methods: {
    auctionTimeLeft(end) {
      const today = new Date();
      const auctionEnd = new Date(end);
      // this.timeLeft = Math.floor((auctionEnd - today) / 1000);
      return Math.floor((auctionEnd - today) / 1000);
    },
    auctionTimeLimit(timeLimit) {
      // this.timeLimit = timeLimit
      return timeLimit;
    },
    filteredObject(inputObject, keysAllowedArray) {
      const raw = inputObject
      const allowed = keysAllowedArray
     const filtered = Object.keys(raw)
  .filter(key => allowed.includes(key))
  .reduce((obj, key) => {
    obj[key] = raw[key];
    return obj;
  }, {});
  return filtered
    },
  },
};
</script>

<style lang="scss">
.tabs a {
  color: white;
}

// .tree-view-item-string {
//   font-family: "Raleway";
//   font-size: 20px
// }

.tree-view-item-node {
  font-family: "Raleway";
  // font-size: 14px
}

// .tabs a:hover {
//     color: $light
// }
</style>
