<template>
  <section class="section">
    <!-- {{ new Date().toDateString()
    }}
    {{ 
      new Date().setDate(new Date().getDate() - 1)


    }} -->
    <nav
      class="breadcrumb is-medium"
      aria-label="breadcrumbs"
    >
      <ul>
        <li class="is-size-3">
          <router-link to="/analytics">
            Analytics
          </router-link>
        </li>
        <li class="is-active is-size-3">
          <router-link to="/analytics/marketAnalytics">
            Market Analytics
          </router-link>
        </li>
      </ul>
    </nav>
    <div class="columns is-multiline">
      <div class="column has-text-centered is-2">
        <div class="box">
          <div class="block">
            <b-icon
              icon="filter"
              size="is-large"
            />
          </div>
         
          <!-- <div class="subtitle">
            Filters
          </div> -->
          <b-field label="products">
            <b-select
              v-model="selectedProducts"
              multiple
              size="is-small"
              @input="filter()"
            >
              <option
                v-for="product in productValues"
                :key="product"
                :value="product"
              >
                {{ product }}
              </option>
            </b-select>
          </b-field>

          <b-field label="clientSegments">
            <b-select
              v-model="selectedClients"
              multiple
              size="is-small"
              @input="filter()"
            >
              <option
                v-for="clientSegment in clientValues"
                :key="clientSegment"
                :value="clientSegment"
              >
                {{ clientSegment }}
              </option>
            </b-select>
          </b-field>
          <b-field label="Months">
            <b-select
              v-model="selectedMonths"
              multiple
              size="is-small"
              @input="filter()"
            >
              <option
                v-for="date in dateValues"
                :key="date"
                :value="date"
              >
                {{ date }}
              </option>
            </b-select>
          </b-field>
          <br>
          <a @click="clearAllFilters()">
        
            <b-icon
              icon="times-circle"
              size="is-medium"
              type="is-link"
            />  <p>clear filters</p></a>
        </div>
      </div>
      <div class="column is-10">
        <div class="columns is-multiline">
          <div class="column is-half has-text-centered">
            <div class="box">
              <div class="subtitle">
                # Auctions per product
              </div>
              <Horizontalbar
                style="height: 250px"
                :chart-data="productBarData"
              />
            </div>
          </div>
          <div class="column is-half has-text-centered">
            <div class="box">
              <div class="subtitle">
                # Auctions per client segment
              </div>
              <Horizontalbar
                style="height: 250px"
                :chart-data="clientBarData"
              />
            </div>
          </div>

          <div class="column is-one has-text-centered">
            <div class="box">
              <div class="subtitle">
                # Auctions trend
              </div>
              <LineChart
                show-legend
                style="height: 250px"
                :chart-data="lineData"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Horizontalbar from "../components/shared/charts/horizontalbar.vue";
import LineChart from "../components/shared/charts/linechart.vue";
// import RadarChart from "../components/shared/charts/radarchart.vue";
// import BarChart from "../components/shared/charts/barchart.vue";
// import Doughnut from '../components/shared/charts/doughnut.vue';
// import AnimatedNumber from '../components/shared/animatedNumber.vue'

import { mapGetters } from "vuex";

export default {
  components: {
    Horizontalbar,
    LineChart,
    // RadarChart,
    // BarChart,
    // Doughnut,
    // AnimatedNumber
  },
  data: () => {
    return {
      selectedClients: [],
      selectedProducts: [],
      selectedMonths: [],
      sourceData: null,
      basicColor: "#003049",
      greenColor: "rgb(65, 184, 131)",

      winningOfferColor: "rgb(65, 184, 131)",
      myOfferColor: "#003049",
    };
  },
  computed: {
    ...mapGetters(["APIsourceData"]),
    selection() {
      return this.selectedClients.concat(this.selectedProducts);
    },

    APIproductValues() {
      const products = this.APIsourceData.map(function (el) {
        return el.product;
      });
      let unique = [...new Set(products)];
      return unique;
    },
    APIclientValues() {
      const clientSegments = this.APIsourceData.map(function (el) {
        return el.clientSegment;
      });
      let unique = [...new Set(clientSegments)];
      return unique;
    },
    APIdateValues() {
      const dates = this.APIsourceData.map(function (el) {
        return el.date;
      });
      let unique = [...new Set(dates)];
      return unique;
    },
    productValues() {
      const products = this.sourceData.map(function (el) {
        return el.product;
      });
      let unique = [...new Set(products)];
      return unique;
    },
    clientValues() {
      const clientSegments = this.sourceData.map(function (el) {
        return el.clientSegment;
      });
      let unique = [...new Set(clientSegments)];
      return unique;
    },
    dateValues() {
      const date = this.sourceData.map(function (el) {
        return el.date;
      });
      let unique = [...new Set(date)];
      return unique;
    },
    totalAuctions() {
      return this.sourceData.length;
    },
    auctionsWon() {
      return this.sourceData.filter((x) => x.winningOffer == x.myOffer).length;
    },
    numberOfAuctionsPerMonth() {
      const mapped = this.dateValues.map((date) => {
        return this.sourceData.filter(function (x) {
          return x.date === date;
        }).length;
      });
      return mapped;
    },
    numberOfAuctionsPerProduct() {
      const mapped = this.productValues.map((product) => {
        return this.sourceData.filter(function (x) {
          return x.product === product;
        }).length;
      });
      return mapped;
    },
    numberOfAuctionsPerClientSegment() {
      const mapped = this.clientValues.map((clientSegment) => {
        return this.sourceData.filter(function (x) {
          return x.clientSegment === clientSegment;
        }).length;
      });
      return mapped;
    },
    /////////////////////////////////////////////// CHARTS ///////////////////////////////////////////////
    clientBarData() {
      return {
        labels: this.clientValues,
        // labels: ["contents", "car", "travel", "home"],
        datasets: [
          {
            label: "# auctions",
            backgroundColor: this.basicColor,
            data: this.numberOfAuctionsPerClientSegment,
            // categoryPercentage: 0.5,
          },
        ],
      };
    },
    productBarData() {
      return {
        labels: this.productValues,
        // labels: ["contents", "car", "travel", "home"],
        datasets: [
          {
            label: "# auctions",
            backgroundColor: this.basicColor,
            data: this.numberOfAuctionsPerProduct,
            // categoryPercentage: 0.5,
          },
        ],
      };
    },
    lineData() {
      return {
        labels: this.dateValues,
        datasets: [
          {
            label: "# auctions",
            borderColor: this.greenColor,
            data: this.numberOfAuctionsPerMonth.filter(Number),
            fill: false,
          },
        ],
      };
    },
  },

  created() {
    this.sourceData = this.APIsourceData;
  },
  methods: {
    filter() {
      if (!this.selectedProducts.length) {
        this.selectedProducts = this.APIproductValues;
      }
      if (!this.selectedClients.length) {
        this.selectedClients = this.APIclientValues;
      }
      if (!this.selectedMonths.length) {
        this.selectedMonths = this.APIdateValues;
      }
      this.sourceData = this.APIsourceData.filter(
        (el) =>
          this.selectedClients.indexOf(el.clientSegment) >= 0 &&
          this.selectedProducts.indexOf(el.product) >= 0 &&
          this.selectedMonths.indexOf(el.date) >= 0
      );
    },
    clearAllFilters() {
      this.selectedProducts = this.APIproductValues;
      this.selectedClients = this.APIclientValues;
      this.selectedMonths = this.APIdateValues;

      this.filter();
    },
  },
};
</script>

<style >

</style>